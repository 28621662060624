import React, {useState} from "react";
import {StyledNumber} from "./ReportSubView";

type NumberState = { isValid:boolean };
type NumberProps = { value:number,decimal:number,style:{}};


export default class CuteNumber extends React.Component<NumberProps,NumberState> {

    constructor(props:NumberProps, state:NumberState) {
        super(props,state);
    }
    render() {
        let {value} = this.props;
        let {decimal} = this.props;
        let {style} = this.props;
        let formattedValue = value.toLocaleString('en-US',{
                minimumFractionDigits: decimal,
                maximumFractionDigits: decimal
            })
        return (<StyledNumber style={style}>{formattedValue}</StyledNumber>);
    }
}