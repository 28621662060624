import React from "react"
import {
    EuiHeader,
    EuiHeaderSectionItem,
    EuiHeaderLinks,
    EuiHeaderLink,
    EuiHeaderLogo
} from "@elastic/eui"
import styled from "styled-components"
import {signOut} from "firebase/auth";
import {authObj} from "../core/firebaseConfig";

const LogoSection = styled(EuiHeaderLink)`
  padding: 0 2rem;
`
function logout() {
    signOut(authObj).then(r => { });
}
export default function Navbar({...props }) {
    return (
        <EuiHeader>
            <EuiHeaderSectionItem border="right">
                <EuiHeaderLogo
                    iconType="logo.png"
                    // href="/logo.png"
                    href="/"
                    style={{width:"auto"}}
                />
            </EuiHeaderSectionItem>

            <EuiHeaderLinks>
                <EuiHeaderLink onClick={logout} >
                    Logout
                </EuiHeaderLink>
            </EuiHeaderLinks>
        </EuiHeader>
    )
}
