import React, {useState} from "react";

import {generateReport} from "../firestore/config/db";
import {
    EuiSpacer,
    EuiSelect,
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow, EuiButton, EuiDatePicker, EuiDatePickerRange,
} from "@elastic/eui";
import moment from "moment";
import {pagesAvailable} from "./Dashboard";
import {UserState} from "./WelcomePage";
import styled from "styled-components";
import {AppReportResult, AppReportStatus, FirestoreRevenueResult} from "../firestore/config/dataModels";
import {ReportSubView} from "./views/ReportSubView";

class selectOptions{
    value:string = ""
    text:string = ""
}

const StyledEuiDatePicker = styled(EuiDatePicker)`
  padding-top: 2px;
  padding-bottom: 2px;
  width:75%
`

type ReportState = { isValid:boolean , info: any , selectedApp:string , startDate:moment.Moment , endDate:moment.Moment , applications:any,results:AppReportStatus,isFormSubmitting:boolean|null};
type ReportProps = { loginUser:UserState , view:string };

export default class Report extends React.Component<ReportProps,ReportState> {

    constructor(props:ReportProps,state:ReportState) {
        console.log("->Report")
        super(props,state);
        this.constructView(props, state)
        }

    private constructView(props: ReportProps, state: ReportState) {
        let {info} = state;
        let {isValid} = state;
        let {selectedApp} = state;
        let {startDate} = state;
        let {endDate} = state;
        let {results} = state;
        let {isFormSubmitting} = state;
        if(startDate==null){
            startDate = moment().subtract(7,'d');
        }
        if(endDate==null){
            endDate = moment().subtract(1,'d');
        }
        let {apps} = props.loginUser
        apps = (apps==null)?[]:apps;
        console.log(apps)
        let applications:selectOptions[] = []
        if(apps.length>1) {
            let choseOption = new selectOptions();
            choseOption.value = "";
            choseOption.text = "All";
            applications.push(choseOption)
        }
        apps.forEach(n=>{
            let singleOption = new selectOptions();
            singleOption.value = n.packageId;
            singleOption.text = n.name;
            applications.push(singleOption)
        })
        this.state = { isValid : (isValid==null) ? false : isValid , info : (info==null) ? [] : info ,
            applications : (applications==null)? [] : applications , selectedApp:(selectedApp==null)?"":selectedApp ,
            startDate:startDate , endDate:endDate , results:(results==null)?{extStatus: false,
                AppResult: []}:results , isFormSubmitting:isFormSubmitting };

    }

    render() {
        let {loginUser} = this.props;
        let {view} = this.props;
        if(view!=pagesAvailable.reports){
            return <div></div>
        }
        return (<div>
            <EuiPanel paddingSize="l">
                <EuiFlexGroup >
                    <h4 style={{textAlign:"center",padding:"0px 20px"}}>{loginUser.currentCompanyName }</h4>
                </EuiFlexGroup>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiFormRow label="Application" helpText="Select application">
                            <EuiSelect
                                // id={basicSelectId}
                                options={this.state.applications}
                                value={this.state.selectedApp}
                                onChange={(e) => this.onSelectedAppChange(e.target.value)}
                                aria-label="Use aria labels when no actual label is in use"
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow label="Start date" helpText="From date">
                            <EuiDatePicker
                                name={"startDate"}
                                dateFormat={"DD/MM/YYYY"}
                                className={"styledDatePicker"}
                                selected={this.state.startDate}
                                onChange={(e)=>{this.onStartDateChange(e)}}
                                adjustDateOnChange={true}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow label="End date" helpText="To date">
                            <EuiDatePicker
                                name={"endDate"}
                                dateFormat={"DD/MM/YYYY"}
                                className={"styledDatePicker"}
                                selected={this.state.endDate}
                                onChange={(e)=>{this.onEndDateChange(e)}}
                                adjustDateOnChange={true}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow label=" &nbsp; " helpText=" &nbsp;  ">
                            <EuiButton style={{margin:"0px auto"}} iconSide="right" onClick={()=>{this.onReportSubmit()}} iconType="arrowRight">
                                submit
                            </EuiButton>
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer size="m" />
            {/*<EuiPanel style={{ display: (this.state.isFormSubmitting==null)?"none" : "flex"  }}>*/}
            {/*    <ReportSubView isFormSubmitting={this.state.isFormSubmitting} results={this.state.results}  loginUser={loginUser}/>*/}

            {/*</EuiPanel>*/}
            <EuiPanel style={{ display: (this.state.isFormSubmitting==null)?"none" : "flex"  }}>
            <ReportSubView isFormSubmitting={this.state.isFormSubmitting} results={this.state.results}  loginUser={loginUser}></ReportSubView>
            </EuiPanel>
        </div>);
    }

    private onStartDateChange(val: moment.Moment | null) {
        if(val==undefined)
            return;
        this.setState({startDate:val});
    }

    private onEndDateChange(val: moment.Moment | null) {
        if(val==undefined)
            return;
        this.setState({endDate:val});
    }

    private onSelectedAppChange(selectedApp: string) {
        this.setState({selectedApp:selectedApp})
    }

    private onReportSubmit(){
        let {loginUser} = this.props;
        let apps:string[] = []
        loginUser.apps.forEach(n=>{
            apps.push(n.packageId)
        })
        this.setState({isFormSubmitting:true})
        generateReport(this.state.selectedApp, this.state.startDate.toDate(), this.state.endDate.toDate(),apps,loginUser.currentCompany)
            .then(res=>{
                this.setState({isFormSubmitting:false,results:res})
            })
            .catch(e=>{console.log(e)})
    }
}