import { query, where , getFirestore , collection, getDocs } from "firebase/firestore";
import { firebaseConfig} from "../../core/firebaseConfig";
import {
    AppInfo,
    AppReportStatus,
    fillArray,
    FirestoreRevenueResult,
    UserDataModel
} from "./dataModels";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import moment from "moment";

firebase.initializeApp(firebaseConfig);

const db = getFirestore();



export async function getCompanyData(uid: string | undefined){
    if(uid==undefined){
        return null;
    }
    const q = query(collection(db, "users"), where("id", "==", uid))
    // const userRef = doc(db,"users","id",uid);
    let data:UserDataModel[] = [];
    let result = await getDocs(q);
    for (let i=0;i<result.size;i++){
        let doc = result.docs[i];
        let row = new UserDataModel();
        row.userid = uid;
        row.company = doc.get("company")
        const q2 = query(collection(db, "company"), where("company", "==", row.company))
        let result2 = await getDocs(q2);
        for (let j=0;j<result2.size;j++){
            let doc2 = result2.docs[j];
            row.companyName = doc2.get("name");
            let snapshot = await firebase.firestore()
                .collection('company')
                .doc(doc2.id)
                .collection('apps')
                .get()
            snapshot.forEach(doc3 =>{
                let appInfo = new AppInfo();
                appInfo.name = doc3.get("name");
                appInfo.packageId = doc3.get("packageId");
                row.apps.push(appInfo);
            })
        }
        data.push(row);
    }
    return data;
}

export async function getApplicationData(company: string) {
    const q2 = query(collection(db, "company"), where("company", "==", company))
    let result2 = await getDocs(q2);
    if(result2.size==0) return null;
    let doc = result2.docs[0];
    let snapshot = await firebase.firestore()
        .collection('company')
        .doc(doc.id)
        .collection('apps')
        .get()
    let finalResult:AppInfo[] = []
    snapshot.forEach(doc3 =>{
        let appInfo = new AppInfo();
        appInfo.name = doc3.get("name");
        appInfo.packageId = doc3.get("packageId");
        finalResult.push(appInfo);
    })
    return finalResult;
}


export async function isValid(uid: string | undefined,company: string | undefined) {
    let qry: firebase.firestore.Query<firebase.firestore.DocumentData> = firebase.firestore().collection("users")
    qry = qry.where("id", "==", uid)
    qry = qry.where("company", "==", company)
    let result = await qry.get();
    return result.size == 1
}

export async function generateReport(app: string, dateFromDash: Date, dateTo: Date, apps: string[],company:string) {
    let qry: firebase.firestore.Query<firebase.firestore.DocumentData> = firebase.firestore().collection("revenue")
    let dateFrom:Date = new Date(dateFromDash.getTime()-(24*60*60*1000));
    let extPresent = false;
    dateFrom.setHours(0);
    dateFrom.setMinutes(0);
    dateFrom.setSeconds(0);
    
    dateFrom.setDate(dateFrom.getDate() + 1);
    if(app!="") {
        qry = qry.where("app", "==", app)
    }
    //qry = qry.where("app", "in", apps)
    qry = qry.where("date", ">=", dateFrom)
    qry = qry.where("date", "<=", dateTo)
    qry = qry.where("companyId", "==", company)
    qry = qry.orderBy("date").orderBy("applications")
    let result = await qry.get();
    let data:FirestoreRevenueResult[] = []
    let extRev,extImp,toatlImp,totalRev,calculatedImpressions,calculatedRevenue;
    let fillRate: fillArray[];
    let fillShow:boolean;

    let total:FirestoreRevenueResult = {id:"Total",appname: "",appId:"", users: 0, date:"", isTotal:true , net:0,ecpm:0,arpdau:0,impressions:0,extEcpm: 0,extRevenue: 0,extImpression: 0,fillRate:[],fillShow:false}
    result.forEach(row => {

            extRev = row.get("externalRevenue")
            extImp = row.get("externalImpression")
            toatlImp =  row.get("impressions")
            totalRev = row.get("netEarnings")
            fillRate = row.get("fillRate")

            if (extRev == undefined || extImp == undefined) {
                extRev = 0;
                extImp = 0;
            }
            else {
                extPresent= true
            }
            fillShow = fillRate != undefined;

            calculatedImpressions = (toatlImp-extImp)
            calculatedRevenue = (totalRev-extRev)

            data.push({
                id: row.id,
                date: moment(row.get("date")["seconds"] * 1000).format("DD/MM/YYYY"),
                appname: row.get("applicationName"),
                appId: row.get("app"),
                impressions: toatlImp-extImp,
                users: row.get("users"),
                ecpm: (calculatedImpressions==0) ? 0 : (calculatedRevenue / calculatedImpressions) * 1000,
                net: totalRev-extRev,
                arpdau: (row.get("users")==0 || row.get("netEarnings")==0 )?0:row.get("netEarnings") / row.get("users"),
                extEcpm: (extRev / extImp) * 1000,
                extRevenue: extRev,
                extImpression: extImp,
                isTotal: false,
                fillRate: fillRate,
                fillShow: fillShow
            })
            total.net += calculatedRevenue
            total.users += row.get("users")
            total.impressions += calculatedImpressions
            total.arpdau += (row.get("users")==0 || row.get("netEarnings")==0 )?0:(row.get("netEarnings") / row.get("users"))
            total.extImpression += extImp
            total.extRevenue += extRev
        })
        if (data.length > 0) {
            total.arpdau = total.arpdau / data.length
            data.push(total)
        }
       let dataResult:AppReportStatus = {
           extStatus: extPresent,
           AppResult: data
        }
        console.log(dataResult)
        return dataResult


}