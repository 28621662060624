import React from "react"
import styled, { ThemeProvider } from "styled-components"
import euiVars from "@elastic/eui/dist/eui_theme_light.json"
import "@elastic/eui/dist/eui_theme_light.css"
import "../assets/css/fonts.css"
import "../assets/css/override.css"
import Navbar from "./Navbar"

const customTheme = {
    ...euiVars,
    euiTitleColor: "dodgerblue"
}

const StyledLayout = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  background: rgb(224, 228, 234);
  display: flex;
  flex-direction: column;
`

const StyledMain = styled.main`
  min-height: calc(100vh - ${(props) => props.theme.euiHeaderHeight} - 1px);
  display: flex;
  flex-direction: column;
`

export default class Layout extends React.Component<{ children: any , enableNavBar:boolean }> {
    render() {
        let {children} = this.props;
        let {enableNavBar} = this.props;
        let navbar = <Navbar/>;
        if(!enableNavBar){
            navbar = <div />;
        }
        return (
            <React.Fragment>
                {/*<Helmet>*/}
                {/*    <meta charSet="utf-8"/>*/}
                {/*    <title>Phresh Cleaners</title>*/}
                {/*    <link rel="canonical" href="https://phreshcleaners.com"/>*/}
                {/*</Helmet>*/}
                <ThemeProvider theme={customTheme}>
                    <StyledLayout>
                        {navbar}
                        <StyledMain>{children}</StyledMain>
                    </StyledLayout>
                </ThemeProvider>
            </React.Fragment>
        )
    }
}
