
export class UserDataModel {
    userid = "";
    company = "";
    apps:AppInfo[] = [];
    companyName = "";
}

export class AppInfo {
    name = "";
    packageId = "";
}

export class AppReportResult{
    date ="";
    appId ="";
    appname ="";
    impressions = 0;
    users = 0;
    ecpm = 0.0;
    net = 0.0;
    arpdau = 0.0;
    isTotal = false;
    extEcpm = 0.0;
    extRevenue = 0.0;
    extImpression = 0;
    
}

export interface AppReportStatus{
    extStatus: boolean,
    AppResult: FirestoreRevenueResult[]
}

export interface FirestoreRevenueResult{
    id: String,
    date : String,
    appId : String,
    appname : String,
    impressions : number,
    users : number,
    ecpm : number,
    net : number,
    arpdau: number,
    isTotal : boolean,
    extEcpm : number,
    extRevenue : number,
    extImpression : number,
    fillRate: fillArray[]
    fillShow: boolean
}

export interface fillArray{
    type : string,
    request: number,
    response: number
}
