import {useEffect, useState} from "react";
import Login from "./Login";
import {authObj} from "../core/firebaseConfig";
import Dashboard, {pagesAvailable} from "./Dashboard";
import {getCompanyData} from "../firestore/config/db";
import {AppInfo, UserDataModel} from "../firestore/config/dataModels";

export function useTitle(title:string) {
    useEffect(() => {
        const prevTitle = document.title
        document.title = title
        return () => {
            document.title = prevTitle
        }
    })
}

export type UserState = { info: UserDataModel[]|null , currentCompany : string, currentCompanyName: string, activeCompanies:any , currentPage:string , apps:AppInfo[] };

export default function WelcomePage() {
    const [userData, setUserData] = useState<UserState|null>(null);
    useEffect(
        () => {
            console.log(" call onAuthStateChanged")
            authObj.onAuthStateChanged((user) => {
                if (user) {
                    //setLoginUser(user.uid)
                    getCompanyData(user.uid)
                        .then(result => {
                            console.log(result);
                            let defaultUserData = userData
                            if (defaultUserData == null) {
                                defaultUserData = {
                                    info: null,
                                    currentCompany: "",
                                    currentCompanyName:"",
                                    activeCompanies: [],
                                    currentPage: pagesAvailable.reports,
                                    apps: []
                                }
                            }
                            defaultUserData.info = result;
                            if (result?.length == 1) {
                                let n = result[0]
                                defaultUserData.currentCompany = n.company
                                defaultUserData.apps = n.apps
                                defaultUserData.currentCompanyName = n.companyName;
                            }
                            setUserData(defaultUserData);
                        })
                        .catch(err => {
                            console.log(err)
                        })
                } else {
                    setUserData({
                        info: [],
                        currentCompany: "",
                        currentCompanyName: "",
                        activeCompanies: [],
                        currentPage: pagesAvailable.reports,
                        apps: []
                    });
                }
            })
        },[]);

    if(userData==null) {
        return (<div style={{padding: "20% 0 20% 0"}}>
            <p style={{textAlign: "center"}}><img src={"loading.gif"} style={{width:"30px"}} /></p>
        </div>);
    } else if(userData.info?.length==0){
        return <Login  />
    } else {
        return <Dashboard loginUser={userData}></Dashboard>;
    }
}