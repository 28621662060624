import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./ui/Login";
import WelcomePage from "./ui/WelcomePage";
// https://codesandbox.io/s/phresh-frontend-part-2-adding-an-elastic-ui-registration-page-7pes7?from-embed=&file=/src/components/Layout/Layout.js:0-1197
function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route path="/login" element={<Login />} />
            {/*<Route path="*" element={<NotFoundPage />} />*/}
          </Routes>
      </BrowserRouter>
  )
}

export default App;
