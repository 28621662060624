import React, {useState} from "react";
import {
    EuiButton,
    EuiPage,
    EuiListGroup,
    EuiListGroupItem,
    EuiCollapsibleNav, EuiTitle, EuiSpacer, EuiFormRow,
    EuiSelect, EuiFlexGroup, EuiFlexItem
} from "@elastic/eui";
import Layout from "./Layout";
import styled from "styled-components";
import {authObj} from "../core/firebaseConfig";
import { signOut } from "firebase/auth";
import {getCompanyData, isValid} from "../firestore/config/db";
import {UserState} from "./WelcomePage";

const pagesAvailable = { home : "home" , reports : "reports" }

const StyledEuiPage = styled(EuiPage)`
  flex: 1;
`

const StyledEuiListGroupItem = styled(EuiListGroupItem)`
  border-radius: 2px;
`
function logout() {
    signOut(authObj).then(r => { });
}

const SubViews = styled(EuiFlexItem)`
    display:none;
`

type HomeState = { isValid:boolean , info: any };
type HomeProps = { loginUser:UserState , view:string };

export default class Home extends React.Component<HomeProps,HomeState> {

    constructor(props:HomeProps,state:HomeState) {
        super(props,state);
        let {info} = state;
        let {isValid} = state;
        this.state = { isValid : (isValid==null) ? false : isValid , info : (info==null) ? [] : info };
    }
    render() {
        let {loginUser} = this.props;
        let {currentCompany} = loginUser;
        let {view} = this.props;
        if(view!=pagesAvailable.home){
            return <div> </div>
        }
        return (<div> home {currentCompany} {loginUser.currentPage} </div>);
    }

}