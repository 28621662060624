import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle
} from "@elastic/eui"
import React from "react";
import styled from "styled-components"
import LoginForm from "./LoginForm";
import Layout from "./Layout";

const StyledEuiPage = styled(EuiPage)`
  flex: 1;
`
const StyledEuiPageHeader = styled(EuiPageHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;

  & h1 {
    font-size: 3.5rem;
  }
`


export default function Login() {
    return (<Layout enableNavBar={false} >
            <StyledEuiPage>
            <EuiPageBody component="section">
                <StyledEuiPageHeader>
                    <EuiPageHeaderSection style={{width:"100%"}}>
                        <EuiTitle size="l">
                            <h1 style={{textAlign:"center"}}>Login</h1>
                        </EuiTitle>
                    </EuiPageHeaderSection>
                </StyledEuiPageHeader>
                <EuiPageContent verticalPosition="center" horizontalPosition="center">
                    <EuiPageContentBody>
                        <LoginForm />
                    </EuiPageContentBody>
                </EuiPageContent>
            </EuiPageBody>
        </StyledEuiPage>
    </Layout>);
}