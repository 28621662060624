import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBErydrlIBtDXhgxeMk2ahMeo9oESOdTVA",
    authDomain: "adpumb-adx.firebaseapp.com",
    databaseURL: "https://adpumb-adx-default-rtdb.firebaseio.com",
    projectId: "adpumb-adx",
    storageBucket: "adpumb-adx.appspot.com",
    messagingSenderId: "590103959702",
    appId: "1:590103959702:web:cb7cf054b30942caa10ab7",
    measurementId: "G-TSPGVML7SB"
};

// Initialize Firebase
export const firebaseAppObj = initializeApp(firebaseConfig);

export const authObj = getAuth(firebaseAppObj);
