import React, {useState} from "react";
import {
    EuiButton,
    EuiPage,
    EuiListGroup,
    EuiListGroupItem,
    EuiCollapsibleNav, EuiTitle, EuiSpacer, EuiFormRow,
    EuiSelect, EuiFlexGroup, EuiFlexItem
} from "@elastic/eui";
import Layout from "./Layout";
import styled from "styled-components";
import {authObj} from "../core/firebaseConfig";
import { signOut } from "firebase/auth";
import {getCompanyData} from "../firestore/config/db";
import Home from "./Home";
import Report from "./Report";
import {UserState} from "./WelcomePage";

export const pagesAvailable = { home : "home" , reports : "reports" }

const StyledEuiPage = styled(EuiPage)`
  flex: 1;
`

const StyledEuiListGroupItem = styled(EuiListGroupItem)`
  border-radius: 2px;
  cursor:pointer;
`
function logout() {
    signOut(authObj).then(r => { });
}

const SubViews = styled(EuiFlexItem)`

`

class selectOptions{
    value:string = ""
    text:string = ""
}


export type UserProps = { loginUser:UserState};

export default class Dashboard extends React.Component<UserProps,UserState> {

    constructor(props:any,state2:UserState) {
        super(props,state2);
        this.state = state2
    }

    private constructUserView(result: UserState) {
        let activeCompanies:any[] = [];
        if(result?.info?.length != 1 ){
            activeCompanies.push({ value: "", text: "Select" })
        }
        let currentCompany = "";
        if(this.state!=undefined){
            currentCompany =  this.state.currentCompany;
        }
        result.currentCompany = (currentCompany==undefined||currentCompany==null)?"":currentCompany;
        result?.info?.forEach(n=>{
            if(result?.info?.length==1){
                result.currentCompany = n.company
                result.apps = n.apps
            }
            activeCompanies.push({ value: n.company, text: n.companyName })
        })
        result.activeCompanies = activeCompanies;
        this.setState(result);

    }
    componentDidMount() {
        let {loginUser} = this.props;
        this.constructUserView(loginUser)
    }

    render() {
        let {loginUser} = this.props;
        if(loginUser==null||loginUser.info?.length==0){
            return <div></div>
        }
        return (<Layout enableNavBar={true} >
            <StyledEuiPage>
                <EuiFlexGroup direction="column">
                    <SubViews grow={false} >
                        <Home loginUser={loginUser} view={this.state.currentPage}  ></Home>
                        <Report  loginUser={loginUser}  view={this.state.currentPage}  ></Report>
                    </SubViews>
                </EuiFlexGroup>
            </StyledEuiPage>
        </Layout>);
    }

    private switchView(view: string) {
        console.log(view)
        switch (view) {
            case pagesAvailable.home: this.setState({currentPage:pagesAvailable.home});  break;
            case pagesAvailable.reports: this.setState({currentPage:pagesAvailable.reports});  break;
        }
    }

    private onCompanyChange(value:string) {
        this.setState({ currentCompany : value })
    }
}