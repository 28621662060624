import React, {ReactNode, useEffect, useState} from "react";
import {AppReportStatus, FirestoreRevenueResult} from "../../firestore/config/dataModels";
import {UserState} from "../WelcomePage";
import {EuiBasicTable, EuiBasicTableColumn, EuiButtonIcon, EuiCard, EuiScreenReaderOnly} from "@elastic/eui";
import CuteNumber from "../views/CuteNumber";
import styled from "styled-components";

export const StyledSpan = styled("span")`
     font-size:12px;
     width: 100%;
     padding: 0 20px;
     text-align:center;
 `



export const StyledSpanHead = styled("span")`
     font-size:13px;
     width: 100%;
     padding: 0 20px;
     text-align:center;
  
 `


export const StyledNumber = styled(StyledSpan)`
     text-align:right;
 `


type SubViewProps = { results:AppReportStatus,isFormSubmitting:boolean|null,loginUser:UserState};






const columnsExt = [
    {
        field: 'date',
        name: 'Date',
        sortable: true,
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <span></span>
            }
            return <StyledSpan>{item}</StyledSpan>
        }
    },
    {
        field: 'appname',
        name: 'Application',
        sortable: true,
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <span></span>
            }
            return <StyledSpan>{item}</StyledSpan>
        }
    },
    // {
    //     field: 'appId',
    //     name: 'APP ID',
    //     sortable: true,
    //     render : (item:any,data:AppReportResult) => {
    //         if(data.isTotal){
    //             return <StyledSpan><b>Total</b></StyledSpan>
    //         }
    //         return <StyledSpan>{data.appId}</StyledSpan>
    //     }
    // },
    {
        field: 'users',
        name: 'Users',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <span></span>
            }
            if(item==0){
                return <span></span>
            }
            return <CuteNumber decimal={0} value={item} style={{}}></CuteNumber>
        }
    },
    {
        field: 'impressions',
        name: 'Impressions',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={0} value={item} style={{'fontWeight': 'bold'}} ></CuteNumber>
            }
            return <CuteNumber decimal={0} value={item} style={{}}></CuteNumber>
        }
    },
    {
        field: 'ecpm',
        name: 'eCPM',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={2} value={ (data.net*1000) / data.impressions} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={2} value={item} style={{}}></CuteNumber>
        }
    },
    {
        field: 'net',
        name: 'Revenue (USD)',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={2} value={item} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={2} value={item} style={{}}></CuteNumber>
        }
    },

    {field: 'extEcpm',
        name: 'External Ecpm',


        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={2} value={ (data.extRevenue*1000) / data.extImpression} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={2} value={getEcpm(item)} style={{}}></CuteNumber>
        }},
    {
        field: 'extImpression',
        name: 'External Impression',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={0} value={item} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={0} value={item} style={{}}></CuteNumber>
        }

    },
    {
        field: 'extRevenue',
        name: 'External Revenue (USD)',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={2} value={item} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={2} value={item} style={{}}></CuteNumber>
        }

    },{
        field: 'arpdau',
        name: 'ARPDAU (USD)',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(item==0){
                return <span></span>
            }
            if(data.isTotal){
                return <span></span> //<CuteNumber decimal={4} value={item} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={4} value={item} style={{}}></CuteNumber>
        }
    }
];

const columns = [
    {
        field: 'date',
        name: 'Date',
        sortable: true,
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <span></span>
            }
            return <StyledSpan>{item}</StyledSpan>
        }
    },
    {
        field: 'appname',
        name: 'Application',
        sortable: true,
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <span></span>
            }
            return <StyledSpan>{item}</StyledSpan>
        }
    },
    // {
    //     field: 'appId',
    //     name: 'APP ID',
    //     sortable: true,
    //     render : (item:any,data:AppReportResult) => {
    //         if(data.isTotal){
    //             return <StyledSpan><b>Total</b></StyledSpan>
    //         }
    //         return <StyledSpan>{data.appId}</StyledSpan>
    //     }
    // },
    {
        field: 'users',
        name: 'Users',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <span></span>
            }
            return <CuteNumber decimal={0} value={item} style={{}}></CuteNumber>
        }
    },
    {
        field: 'impressions',
        name: 'Impressions',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={0} value={item} style={{'fontWeight': 'bold'}} ></CuteNumber>
            }
            return <CuteNumber decimal={0} value={item} style={{}}></CuteNumber>
        }
    },
    {
        field: 'ecpm',
        name: 'eCPM',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={2} value={ (data.net*1000) / data.impressions} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={2} value={item} style={{}}></CuteNumber>
        }
    },
    {
        field: 'net',
        name: 'Revenue (USD)',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={2} value={item} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={2} value={item} style={{}}></CuteNumber>
        }
    },
    {
        field: 'arpdau',
        name: 'ARPDAU (USD)',
        render : (item:any,data:FirestoreRevenueResult) => {
            if(data.isTotal){
                return <CuteNumber decimal={4} value={item} style={{'fontWeight': 'bold'}}></CuteNumber>
            }
            return <CuteNumber decimal={4} value={item} style={{}}></CuteNumber>
        }
    }
];

export function  getColumn(extStatus:boolean){
    console.log(extStatus)
    if(extStatus){
        return columnsExt
    }

    return columns

}
function getEcpm(ecpmValue:any){
    if(isNaN(ecpmValue)){
        return 0
    }
    else {
        return ecpmValue
    }
}

export const ReportSubView: (props: SubViewProps) => JSX.Element = (props:SubViewProps) => {


    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<
        Record<string, ReactNode>
    >({});
    const [isFormSubmitting,setIsFormSubmitting] = useState(props.isFormSubmitting);
    const [result,setResult] = useState(props.results)


    useEffect(()=>{
        setIsFormSubmitting(props.isFormSubmitting)
        setResult(props.results)
    },[props.isFormSubmitting,props.results])


    function getIndexOf(id: String) {
        return props.results.AppResult.findIndex(p => p.id == id);
    }

    const columnsWithExpandingRowToggle: Array<EuiBasicTableColumn<FirestoreRevenueResult>> = [
        ...getColumn(result.extStatus),
        {
            align: 'right',
            width: '40px',
            isExpander: true,
            name: (
                <EuiScreenReaderOnly>
                    <span>Expand rows</span>
                </EuiScreenReaderOnly>
            ),

            render: (user: FirestoreRevenueResult) => {
                const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };

                if(user.isTotal || !(user.fillShow)){
                    return <span></span>
                }
                return (
                    <EuiButtonIcon
                        onClick={() => toggleDetails(user)}
                        aria-label={
                            itemIdToExpandedRowMapValues[getIndexOf(user.id)] ? 'Collapse' : 'Expand'
                        }
                        iconType={
                            itemIdToExpandedRowMapValues[getIndexOf(user.id)] ? 'arrowDown' : 'arrowRight'
                        }
                    />
                );
            },
        },
    ];


    // function getFillList(fillRate: fillArray[]) {
    //     let fillArr : {
    //         title: string,
    //         description: string,
    //     }[] = [];
    //     fillRate.map((data)=>{
    //         let tempFillArrDetail = {title: "loading",description: "loading"};
    //         tempFillArrDetail.title = data.type;
    //         tempFillArrDetail.description = "Request : " + data.request+"        Response  : " + data.response;
    //         fillArr.push(tempFillArrDetail)
    //     })
    //
    //     return fillArr;
    //
    // }

    const toggleDetails = (user: FirestoreRevenueResult) => {
        const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };

        if (itemIdToExpandedRowMapValues[getIndexOf(user.id)]) {
            delete itemIdToExpandedRowMapValues[getIndexOf(user.id)];
        } else {



            // const listItems = getFillList(user.fillRate);
            itemIdToExpandedRowMapValues[getIndexOf(user.id)] = (
                user.fillRate.map((data)=>{
                    return(<EuiCard paddingSize={"none"} title={""}><span style={{fontSize:"12px"}}>{data.type}</span><br/><span style={{fontSize:"11px"}}>Fill Rate : {((data.response/data.request)*100).toFixed(2)} % </span></EuiCard>)
                })
            );
        }
        setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
    };


    if(isFormSubmitting==null){
        return <div></div>
    }
    if(isFormSubmitting){
        return <img src={"loading.gif"} style={{width:"20px",margin:"20px auto"}}  alt={"Loading.."}/>
    }

    return (

        <EuiBasicTable
            id={"custom-report"}
            items={result.AppResult}
            // columns={getColumn(result.extStatus)}
            tableLayout={'auto'}
            style={{width: "100%"}}
            itemIdToExpandedRowMap={itemIdToExpandedRowMap}
            isExpandable={true}
            columns={columnsWithExpandingRowToggle}
        />
    )

}