import {EuiButton, EuiFieldPassword, EuiFieldText, EuiForm, EuiFormRow, EuiSpacer ,
    EuiFlexGroup,
    EuiFlexItem, } from "@elastic/eui";
import React, {FormEvent, useState} from "react";
import styled from "styled-components";
import {authObj, firebaseAppObj} from "../core/firebaseConfig";
import {  signInWithEmailAndPassword } from "firebase/auth";
import {Simulate} from "react-dom/test-utils";


const LoginFormWrapper = styled.div`
  padding: 2rem;
`
const FormContentsWrapper = styled.div`
  display:block
`

export default function Login() {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loginState, setLoginState] = useState<boolean|null>(null);

    const handleSubmit = (event:FormEvent) => {
        event.preventDefault();
        setLoginState(true)
        signInWithEmailAndPassword(authObj,userName,password)
            .then((userCredential) => {
                setLoginState(true)
                const user = userCredential.user;
                console.log(user);
            })
            .catch((er)=>{
                setLoginState(false)
            })
    }
    if(loginState!=null&&loginState){
        return (<img src={"loading.gif"} style={{width:"30px"}} />)
    }
    return (<LoginFormWrapper>
        <EuiForm component="form" action={"login"} onSubmit={handleSubmit} >
            <FormContentsWrapper>
                <EuiFormRow
                            label="Email"
                            fullWidth
                            helpText="Enter the email associated with your account."
                            error={`Please enter a valid email.`}
                        >
                    <EuiFieldText
                                icon="email"
                                placeholder="user@mymail.com"
                                aria-label="Enter the email associated with your account."
                                style={{height:"auto",width:"auto"}}
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                </EuiFormRow>
                <EuiFormRow
                    label="Password"
                    helpText="Enter your password."
                    error={`Password must be at least 7 characters.`}
                >
                    <EuiFieldPassword
                        placeholder="••••••••••••"
                        type="dual"
                        aria-label="Enter your password."
                        style={{height:"auto"}}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </EuiFormRow>
                <EuiSpacer />
                {loginState==null?<div></div> : loginState? <div><span style={{color:"#0F0" }}>Logged in...</span></div> : <div><span style={{color:"#F00" }}>Wrong credentials...</span></div> }
                <EuiSpacer />
                <EuiButton type="submit" fill>
                    Submit
                </EuiButton>
            </FormContentsWrapper>
        </EuiForm>
        <EuiSpacer size="xl" />
    </LoginFormWrapper>);
}